"use client";

import { useEffect } from "react";
import { PageTypes, setPageType } from "../../utils/fnpPageType";
import useAnalytics from "../../analytics/useAnalytics";
import { cleverTapCommonEvents, validationFor } from "../../analytics/clevertapConstants";
import { usePathname } from "next/navigation";
import { shallowEqual, useSelector } from "react-redux";

/**
 *
 * @param {*} param0
 * @returns
 */
const NotFoundClient = () => {
  const { trackEvent } = useAnalytics();
  const pathname = usePathname();
  const { loggedin } = useSelector(
    (state) => ({
      loggedin: state.fus.loginDetail?.loggedin,
    }),
    shallowEqual,
  );

  useEffect(() => {
    /**
     * set fnp page type for error page
     */
    setPageType(PageTypes.ERROR_404);

    const errorValidationData = {
      page_name: pathname,
      page_type: PageTypes.ERROR_404,
      error_code: "404",
      validation_for: validationFor.OOPS_PAGE_NOT_FOUND,
      validation_text: validationFor.OOPS_PAGE_NOT_FOUND,
      login_status: loggedin,
    };

    trackEvent(cleverTapCommonEvents.validationViewed, errorValidationData, ["clevertap"]);
  }, [loggedin]);

  return <></>;
};

export default NotFoundClient;
